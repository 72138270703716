import { faSave, faTrash } from "@fortawesome/pro-light-svg-icons";
import {
  AdHocInsightDto,
  AdHocInsightEntity,
  AdHocRule,
  excelParseProcess,
  initialRule,
  isValidCells,
  isValidFileName,
  isValidSheetName,
  makeWorkbook,
  parseCSV,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import ExcelJS from "exceljs";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { unexpectedError } from "../../../../constants";
import { putAdHocInsight } from "../../../../services/adHocInsightService";
import withAlertModal, {
  WithAlertModalProps,
} from "../../../common/alert/withAlertModal";
import ButtonWithIcon from "../../../common/button/ButtonWithIcon";
import UncontrolledModal from "../../../common/modal/UncontrolledModal";
import { AdHocInsightRuleEditor } from "../AdHocInsightRuleEditor";

export interface TestProcessModalProps {
  currentSelected: AdHocInsightEntity;
  onClose: () => void;
}

const TestProcessModal = (
  props: TestProcessModalProps & WithAlertModalProps,
) => {
  const { currentSelected, onClose, setAlert } = props;

  const [serverError, setServerError] = useState<string>();
  const [testFile, setTestFile] = useState<File>();
  const [originInsight, setOriginInsight] =
    useState<AdHocInsightEntity>(currentSelected);
  const [rule, setRule] = useState<AdHocRule>(
    currentSelected.rule ?? initialRule,
  );
  const [ruleErrors, setRuleErrors] = useState<Record<string, any>>([]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length) setTestFile(acceptedFiles[0]);
      else setAlert("File type is not correct!", false);
    },
    [setAlert],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ],
    maxFiles: 1,
    multiple: false,
  });

  const handleSaveRule = async () => {
    setServerError(undefined);
    if (ruleErrors?.length === 0 && originInsight) {
      const dto: AdHocInsightDto = {
        ...originInsight,
        rule,
      };
      try {
        const updatedAdHocInsight = await putAdHocInsight(
          currentSelected.id,
          dto,
        );
        setOriginInsight(updatedAdHocInsight);
        setAlert("Ad Hoc Insight Rule is updated successfully!", true);
      } catch (err: any) {
        const response = err.response;
        setServerError(
          response?.status === 400 && response.data.message
            ? response.data.message
            : unexpectedError,
        );
      }
    }
  };

  const downloadExcel = async (rule: AdHocRule, outData: Array<any>) => {
    const workbook = makeWorkbook(rule, outData);

    const buffer = await workbook.xlsx.writeBuffer();
    const workbookBlob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const workbookUrl = URL.createObjectURL(workbookBlob);

    const downloadElement = document.createElement("a");
    downloadElement.href = workbookUrl;
    downloadElement.download = "output.xlsx";
    document.body.appendChild(downloadElement);
    downloadElement.click();

    document.body.removeChild(downloadElement);
    URL.revokeObjectURL(workbookUrl);
  };

  const handleTestExcel = async () => {
    if (!testFile) {
      setAlert("No file to read", false);
      return;
    }

    if (!isValidFileName(rule, testFile.name)) {
      setAlert("File name dismatched error!", false);
      return;
    }

    let arrayBuffer;
    let workbook;

    if (testFile.name.endsWith(".xlsx")) {
      try {
        arrayBuffer = await testFile.arrayBuffer();
        workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(arrayBuffer);
      } catch (e) {
        setAlert(`Failed to load XLSX file!`, false);
        return;
      }
    } else if (testFile.name.endsWith(".csv")) {
      try {
        const csvArrayBuffer = await testFile.arrayBuffer();
        workbook = parseCSV(csvArrayBuffer);
      } catch (e) {
        setAlert(`Failed to load CSV file!`, false);
        return;
      }
    } else {
      setAlert("File format not supported!", false);
      return;
    }

    const targetSheet = isValidSheetName(rule, workbook);
    if (!targetSheet) {
      setAlert("Sheet name dismatched error!", false);
      return;
    }

    if (!isValidCells(rule, targetSheet)) {
      setAlert("Cell value dismatched error!", false);
      return;
    }

    let outData: Array<any[]>;

    try {
      outData = excelParseProcess(rule, targetSheet, testFile.name);
    } catch (e: any) {
      setAlert(e.message, false);
      return;
    }

    await downloadExcel(rule, outData);

    setAlert("Successfully parsed!", true);
  };

  return (
    <UncontrolledModal onClosed={onClose}>
      <ModalHeader>{`Test Rule - Ad Hoc Insight ( ${currentSelected?.name} )`}</ModalHeader>
      <ModalBody>
        {!testFile ? (
          <div
            {...getRootProps({
              className: clsx(
                "flex-col p-5 justify-content-center align-items-center generic-drop",
                {
                  isDragWaiting: !isDragActive,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                },
              ),
            })}
          >
            <input {...getInputProps()} />
            Drag your Excel or CSV file here, or click to select.
          </div>
        ) : (
          <div className="flex-col justify-content-center gap-3 p-3 align-items-center">
            File is uploaded successfully.
            <ButtonWithIcon
              icon={faTrash}
              onClick={() => setTestFile(undefined)}
              label="Remove File"
            />
          </div>
        )}
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <FormGroup row style={{ marginTop: 15 }}>
          <Col sm={12}>
            <AdHocInsightRuleEditor
              rule={rule}
              setRule={setRule}
              setRuleErrors={setRuleErrors}
            />
          </Col>
        </FormGroup>
        <div className="d-flex w-100 justify-content-end">
          <ButtonGroup className="d-flex gap-2">
            <ButtonWithIcon
              icon={faSave}
              onClick={handleSaveRule}
              label="Save"
            />
          </ButtonGroup>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button disabled={!testFile} color="primary" onClick={handleTestExcel}>
          Test
        </Button>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default withAlertModal(TestProcessModal);
