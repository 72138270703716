import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { find } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Input, Label, Spinner } from "reactstrap";
import dashboardService from "../../../../services/dashboardService";
import { IndependentImageInsightId } from "../../../../services/insightLibrary/insightDefinitions";
import insightLibraryService from "../../../../services/insightLibrary/insightsLibraryService";
import { apiUrl } from "../../../../utils/api";
import { usePropertiesSelectionQueryParam } from "../../../../utils/useQueryParams";
import { ValidationError } from "../../../admin/integrations/addEditIntegrationModal/common";
import { WithAlertModalProps } from "../../../common/alert/withAlertModal";

interface ImageCardPropsType {
  dashboardInsight?: Record<string, any>;
  validationError: ValidationError;
  onClose: () => void;
  onSubmit: () => void;
  onUnexpectedError: (e?: any) => void;
  updateDashboardInsight: (fieldName: string, newData: any) => void;
}

export const ImageCard = (props: ImageCardPropsType & WithAlertModalProps) => {
  const {
    dashboardInsight,
    setAlert,
    onUnexpectedError,
    updateDashboardInsight,
  } = props;

  const [selection] = usePropertiesSelectionQueryParam();

  const [uploading, setUploading] = useState(false);

  const updateBasicConfig = useCallback(
    (additional?: any) => {
      const defaultStuff = dashboardInsight?.stuff;
      updateDashboardInsight("stuff", {
        ...defaultStuff,
        ...additional,
      });

      updateDashboardInsight("linkedReport", undefined);
      updateDashboardInsight("name", "Image card");
    },
    [dashboardInsight?.stuff, updateDashboardInsight],
  );

  const updateImageGuid = useCallback(
    (imageGuid?: string) => {
      updateBasicConfig({
        imageGuid,
        isPropertyImage: false,
      });
    },
    [updateBasicConfig],
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        setUploading(true);
        const result: any = await dashboardService.uploadCardImage(
          acceptedFiles[0],
        );
        updateImageGuid(result.data.guid);
        setUploading(false);
      } else
        setAlert(
          "Image type is not correct. The following file types are supported: JPEG, PNG",
          false,
        );
    },
    [setAlert, updateImageGuid],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: ["image/jpeg", "image/png"],
    disabled: uploading,
    maxFiles: 1,
    multiple: false,
  });

  const updatePropertyCheckBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateBasicConfig({ isPropertyImage: e.target.checked });
    },
    [updateBasicConfig],
  );

  useEffect(() => {
    const setupInitialData = async () => {
      try {
        const insights = await insightLibraryService.getInsights();
        const imageInsight = find(insights, { id: IndependentImageInsightId });

        updateDashboardInsight("insight", imageInsight);
      } catch (error) {
        onUnexpectedError(error);
      }
    };

    setupInitialData();
  }, [onUnexpectedError, updateDashboardInsight, selection]);

  return (
    <div style={{ padding: 5 }}>
      {!dashboardInsight?.stuff?.imageGuid ? (
        <div
          {...getRootProps({
            className: clsx(
              "flex-col p-5 justify-content-center align-items-center generic-drop",
              {
                isDragWaiting: !isDragActive,
                isDragActive,
                isDragAccept,
                isDragReject,
              },
            ),
          })}
        >
          <input {...getInputProps()} />
          {uploading ? (
            <>
              <div>Processing</div>
              <Spinner />
            </>
          ) : (
            <>
              <div>Drag your image here, or click to select.</div>
            </>
          )}
        </div>
      ) : (
        <div className="flex-col justify-content-center gap-3 p-3 align-items-center position-relative">
          {dashboardInsight?.stuff?.imageGuid != null ? (
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "15rem",
                borderRadius: "5px",
              }}
              src={apiUrl(
                `/dashboards/image/${dashboardInsight?.stuff?.imageGuid}`,
              )}
              alt=""
            />
          ) : null}
          <FontAwesomeIcon
            className="weekly-coffee position-absolute close-btn"
            icon={faTrash}
            style={{
              bottom: 6,
              right: 3,
              height: 28,
              width: 28,
              borderRadius: 10,
              color: "red",
            }}
            onClick={(e) => updateImageGuid()}
          />
        </div>
      )}
      <Label row="true" check className="mt-2 ms-1">
        <Input
          type="checkbox"
          checked={dashboardInsight?.stuff?.isPropertyImage}
          onChange={updatePropertyCheckBox}
        />
        <span className="ps-2">Display Property Image if Available</span>
      </Label>
    </div>
  );
};
