import "./loading.css";

import React from "react";
import { Spinner } from "reactstrap";

import { ReactComponent as Logo } from "../../images/logo-h.svg";

const Loading: React.FC<{ joyhubLogo?: boolean }> = ({ joyhubLogo }) => {
  return joyhubLogo ? (
    <div className="loading-page-main" style={{ opacity: 0.5 }}>
      <Logo id="loading-logo" style={{ fill: "#0e1553" }} />
      <Spinner id="loading-spinner" color="secondary" type="border" />
    </div>
  ) : (
    <div className="loading-page-main inside" style={{ opacity: 0.5 }}>
      <Spinner id="loading-spinner" color="secondary" type="border" />
    </div>
  );
};

export default Loading;
