import {
  faFileDownload,
  faLayerPlus,
  faPlus,
  faPrint,
} from "@fortawesome/pro-light-svg-icons";
import {
  AllInsightsData,
  exhaustiveCheck,
  formatCellValue,
  isAllInsights,
  isInsightsByIds,
  PureDate,
} from "@joyhub-integration/shared";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import { useSet } from "react-use";

import { downloadAllData, getAllData } from "../../services/exportService";
import { downloadAttachment } from "../../utils/download";
import {
  useInsightsSelectionQueryParam,
  usePropertiesSelectionQueryParam,
} from "../../utils/useQueryParams";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import { useLoadilyFadily } from "../common/allFadily";
import ActionBar from "../common/button/ActionBar";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import PrintHeader from "../common/PrintHeader";
import { ModernCrumbar } from "../layout/ModernCrumbar";
import InsightsPicker from "../picker/InsightsPicker";
import AddInsightDataModal from "./AddInsightDataModal";

const InsightsDataScreen: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
}) => {
  const [allData, setAllData] = useState<AllInsightsData<true>>();
  const [downloading, setDownloading] = useState(false);
  const [, { has: insightExpanded, add: expandInsight }] = useSet<string>();
  const [date, setDate] = useState(new Date());
  const [pSelection] = usePropertiesSelectionQueryParam();
  const [iSelection] = useInsightsSelectionQueryParam();
  const { insightsMap } = useContext(PlatformContext).platform!;
  const [addDataModalOpen, setAddDataModalOpen] = useState(false);

  const fetchAllData = useCallback(async () => {
    if (pSelection != null) {
      setAllData(undefined);

      try {
        const data = await getAllData({
          date: new PureDate(date),
          properties: pSelection,
        });
        setAllData(data);
      } catch (e) {
        onUnexpectedError(e);
      }
    }
  }, [pSelection, onUnexpectedError, date]);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  const insights = useMemo(() => Object.values(insightsMap), [insightsMap]);

  const doDownload = () => {
    setDownloading(true);
    downloadAllData({
      date: new PureDate(date),
      properties: pSelection ?? {},
    })
      .then(downloadAttachment("Insights"))
      .catch(onUnexpectedError)
      .finally(() => setDownloading(false));
  };

  const filteredRows = useMemo(() => {
    if (iSelection == null || isAllInsights(iSelection)) {
      return allData?.rows;
    } else if (isInsightsByIds(iSelection)) {
      return allData?.rows.filter((row) => iSelection.ids.includes(row.id));
    }
    exhaustiveCheck(iSelection);
  }, [allData, iSelection]);

  const fadily = useLoadilyFadily(true);

  const visited = new Set<string>();

  const handleAddDataModalClose = async () => {
    setAddDataModalOpen(false);
  };

  return (
    <>
      <ModernCrumbar primary="Insight Data" />
      <div className="jh-page-layout">
        <ActionBar
          buttonProps={[
            <ButtonWithIcon
              icon={faPrint}
              tooltip="Print"
              className="jh-action-icon"
              onClick={() => window.print()}
              id="print-insights"
            />,
            <ButtonWithIcon
              icon={faFileDownload}
              tooltip="Download"
              className="jh-action-icon"
              onClick={doDownload}
              disabled={downloading}
              id="download-insights"
              style={downloading ? { cursor: "wait" } : {}}
            />,
            <ButtonWithIcon
              icon={faLayerPlus}
              tooltip="Add Data"
              className="jh-action-icon"
              onClick={() => setAddDataModalOpen(true)}
              id="add-insights"
            />,
          ]}
          rightButtonProps={[
            <InsightsPicker insights={insights} className="me-2" />,
            <DatePicker
              value={date}
              onChange={setDate}
              clearIcon={null}
              calendarIcon={null}
              className="jh-date-picker btn btn-light"
            />,
          ]}
        />
        <div
          className="jh-page-content pt-0 dashboard-insight-main"
          style={fadily}
        >
          <PrintHeader
            title="Insight Data"
            fullDate
            date={PureDate.of(allData?.newest)}
          />
          {filteredRows ? (
            <div className="chart-table sticky-admin-header">
              <div className="generic-report-table row-borders">
                <table className="generic-report-table-root">
                  <thead>
                    <tr>
                      <th colSpan={2}>Name</th>
                      <th className="Insight">Insight</th>
                      <th className="Insight">Goal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredRows.map(
                      (
                        { id, name, value, goal, dimensions, numFmt },
                        index,
                      ) => {
                        if (dimensions != null && !insightExpanded(name)) {
                          if (visited.has(name)) {
                            return null;
                          } else {
                            visited.add(name);
                            return (
                              <tr key={index}>
                                <td>
                                  <Link to={`/insights/${id}`}>{name}</Link>
                                </td>
                                <td
                                  colSpan={3}
                                  style={{
                                    borderLeft: "none",
                                    backgroundColor: "rgba(0,0,0,.03)",
                                    textAlign: "center",
                                  }}
                                >
                                  <ButtonWithIcon
                                    color="link"
                                    icon={faPlus}
                                    iconSize="sm"
                                    className="py-0 w-100"
                                    onClick={() => expandInsight(name)}
                                  />
                                </td>
                              </tr>
                            );
                          }
                        } else {
                          return (
                            <tr key={index}>
                              <td colSpan={dimensions ? 1 : 2}>
                                <Link to={`/insights/${id}`}>{name}</Link>
                              </td>
                              {dimensions ? (
                                <td style={{ borderLeft: "none" }}>
                                  {dimensions.map((dimension, index) => (
                                    <React.Fragment key={index}>
                                      {index ? <br /> : null}
                                      {dimension}
                                    </React.Fragment>
                                  ))}
                                </td>
                              ) : null}
                              <td className="Insight">
                                {formatCellValue(value, numFmt)}
                              </td>
                              <td className="Insight">
                                {formatCellValue(goal, numFmt)}
                              </td>
                            </tr>
                          );
                        }
                      },
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  borderBottom: "1px solid black",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  textShadow: "0 0 10px rgba(0,0,0,0.5)",
                  color: "rgba(0, 0, 0, 0)",
                }}
              >
                <div>Insight</div>
                <div>Value</div>
              </div>
              <div
                className="skeleton-main"
                style={{
                  height: "600px",
                }}
              />
            </div>
          )}
        </div>
      </div>
      {addDataModalOpen ? (
        <AddInsightDataModal
          selectedProperties={pSelection || {}}
          selectedInsights={iSelection || {}}
          selectedDate={date}
          handleClose={handleAddDataModalClose}
          fetchAllData={fetchAllData}
        />
      ) : null}
    </>
  );
};

export default withAlertModal(InsightsDataScreen);
