import { faFolderTree } from "@fortawesome/pro-light-svg-icons";
import {
  CauseEntity,
  ComputeHistoryEntity,
  dateStr,
  durationStr,
  notNull,
} from "@joyhub-integration/shared";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";

import { keyBy } from "lodash";
import { getCauses } from "../../../services/causeService";
import {
  getComputeHistory,
  getIntegrationById,
  Integration,
} from "../../../services/integrationsService";
import { dateOf } from "../../../utils/date";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";

type ComputeHistoryParams = {
  id: string;
};

const Limit = 20_000;
const Chunk = 500;
const InitialMinimumVisible = 100;

type AnnotatedCHE = ComputeHistoryEntity<true> & {
  startedms: number;
  updatedms: number;
  duration: number;
  incomplete: number;
  cause: CauseEntity | undefined;
};

// Add various fields to a CHE to make aggregation easier.
const annotate = (
  orig: ComputeHistoryEntity<true>[],
  causes: CauseEntity[],
): AnnotatedCHE[] => {
  const causesById = keyBy(causes, "id");
  return orig.map((che) => {
    const startedms = che.started ? new Date(che.started).getTime() : 0;
    const updatedms = che.updated ? new Date(che.updated).getTime() : 0;
    const cause = causesById[che.cause_id];
    return {
      ...che,
      startedms,
      updatedms,
      duration: updatedms > startedms ? updatedms - startedms : 0,
      incomplete: che.updated ? 0 : 1,
      cause,
    };
  });
};

// Merge adjacent (within 2s of each other) history entries of the same type.
// Particularly this is to squish the hundreds/thousands of log entries a CM
// recompute generates.  History is maintained in backwards order (the sort
// here should be a no-op), so the backwards merging is a little tricky but eh.
const compressHistory = (orig: AnnotatedCHE[]): AnnotatedCHE[] =>
  orig
    .sort((a, b) => b.startedms - a.startedms || b.id - a.id)
    .reduce((agg, cur) => {
      const l = agg.length;
      if (!l) {
        agg.push(cur);
      } else {
        const prev = agg[l - 1];
        if (
          prev.common !== cur.common ||
          (cur.updatedms || cur.startedms) + 2000.0 <= prev.startedms ||
          prev.cause_id !== cur.cause_id
          // || cur.id % 10 === 0 // Artificial breaks for testing
        ) {
          agg.push(cur);
        } else {
          prev.errors.unshift(...cur.errors);
          prev.failure_count += cur.failure_count;
          prev.success_count += cur.success_count;
          prev.duration += cur.duration;
          // updated = the last time anything in this stack checked in
          if (cur.updatedms > prev.updatedms) {
            prev.updatedms = cur.updatedms;
            prev.updated = cur.updated;
          }
          if (cur.startedms > prev.updatedms) {
            prev.updatedms = cur.startedms;
            prev.updated = cur.started;
          }
          if (cur.startedms && cur.startedms < prev.startedms) {
            prev.startedms = cur.startedms;
            prev.started = cur.started;
          }
          prev.incomplete += cur.incomplete;
        }
      }
      return agg;
    }, [] as AnnotatedCHE[]);

const prettyDetail = (d: any) =>
  !d || typeof d !== "object" ? (
    <p>No details.</p>
  ) : (
    <pre style={{ whiteSpace: "pre-wrap" }}>
      {" "}
      {JSON.stringify(d, undefined, 2)}{" "}
    </pre>
  );

export const tooltippedCause = (id: number, c: CauseEntity | undefined) => (
  <>
    <div id={`cause-${id}`}>{c?.event ?? "?"}</div>
    <UncontrolledTooltip target={`cause-${id}`} style={{ textAlign: "left" }}>
      <p>ID: {c?.id ?? "?"}</p>
      <p>{c?.occurred?.toString() ?? "unknown time"}</p>
      {prettyDetail(c?.detail)}
    </UncontrolledTooltip>
  </>
);

const ComputeHistoryPage: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
}) => {
  const params = useParams<ComputeHistoryParams>();
  const [computeHistory, setComputeHistory] = useState<Array<AnnotatedCHE>>([]);
  const [offset, setOffset] = useState(0);
  const [more, setMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<AnnotatedCHE>();
  const [integration, setIntegration] = useState<Integration>();
  const [loaded, setLoaded] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  // So we (probably) get a fixed starting point for pagination to work, only
  // pull history prior to this moment.
  const [before] = useState(new Date().getTime());
  const systemId = parseInt(params.id!);
  const { admin, organization } = useContext(PlatformContext).platform!;

  // useCallback will create a new function when the dependency array changes.
  const loadHistory = useCallback(async () => {
    setLoading(true);
    let newOffset = offset;
    let newHistory = computeHistory;
    const targetLength =
      newHistory.length < InitialMinimumVisible
        ? InitialMinimumVisible
        : newHistory.length + 1;
    // newOffset gets checked vs Limit twice lest we do anything when
    // called after hitting Limit (which uh shouldn't happen but).
    while (newOffset < Limit && newHistory.length < targetLength) {
      const plainHist = await getComputeHistory(
        systemId,
        before,
        newOffset,
        Chunk,
      );
      const causes = admin
        ? await getCauses(plainHist.map((h) => h.cause_id).filter(notNull))
        : [];
      const moreHist = annotate(plainHist, causes);
      newHistory = compressHistory([...newHistory, ...moreHist]);
      newOffset += moreHist.length;
      if (moreHist.length < Chunk || newOffset >= Limit) {
        setMore(false);
        break;
      }
    }
    // No update if no change; otherwise .. infinite loops..
    if (newOffset > offset) {
      setComputeHistory(newHistory);
      setOffset(newOffset);
    }
    setLoading(false);
  }, [admin, before, computeHistory, offset, systemId]);

  // useEffect will run the function inside when the dependency array changes.
  useEffect(() => {
    Promise.all([loadHistory(), getIntegrationById(systemId)])
      .then(([, i]) => setIntegration(i))
      .catch(onUnexpectedError)
      .finally(() => {
        setLoaded(true);
      });
  }, [loadHistory, onUnexpectedError, systemId]);

  const loadMore = () => {
    if (!loading) loadHistory().then(() => {});
  };

  const causeCols: Array<KeyValue<AnnotatedCHE>> = admin
    ? [
        {
          key: "cause",
          title: "Cause",
          toValue: ({ id, cause }) => tooltippedCause(id, cause),
        },
      ]
    : [];
  const tableCols: Array<KeyValue<AnnotatedCHE>> = [
    // {
    //   key: "id",
    //   title: "ID",
    //   toValue: (s) => s.id,
    // },
    {
      key: "started",
      title: "Started",
      toValue: (s) => dateStr(s.started),
      sortValue: (s) => dateOf(s.started)?.getTime(),
    },
    {
      key: "common",
      title: "Type",
      toValue: (s) => (s.common ? "CM" : "legacy"),
    },
    {
      key: "updated",
      title: "Duration",
      toValue: (s) =>
        durationStr(s.duration) +
        (s.updatedms <= s.startedms
          ? ""
          : " (" + durationStr(s.updatedms - s.startedms) + " elapsed)") +
        (s.incomplete ? ` … (${s.incomplete} incomplete?)` : ""),
    },
    { key: "success_count", title: "Compute Successes" },
    { key: "failure_count", title: "Compute Failures" },
    ...causeCols,
  ];

  const buttonProps: ButtonWithIconProps[] = [
    {
      label: "Errors",
      icon: faFolderTree,
      onClick: () => setShowErrors(true),
      className: "jh-btn-primary",
      disabled: !selected?.errors.length,
    },
  ];

  return (
    <>
      <ModernCrumbar
        primary="Manage Integrations"
        primaryPath="/admin/integrations"
        secondary={`"${integration?.name ?? "?"}" Compute History for ${
          organization?.name ?? "Unknown org"
        } (${integration?.vendor ?? "?"})`}
      />
      <LoadilyFadily loaded={loaded} className="jh-page-layout">
        <ActionBar buttonProps={buttonProps} />
        <div className="jh-page-content pt-0 admin-page page-scroll">
          <TableWithSelection<AnnotatedCHE>
            selected={selected}
            onSelectedChange={(selected) => setSelected(selected)}
            columns={tableCols}
            rows={computeHistory}
            sortColumn="started"
            sortDirection="desc"
            moar={more ? loadMore : undefined}
          />
        </div>
      </LoadilyFadily>
      {showErrors && selected ? (
        <Modal isOpen toggle={() => setShowErrors(false)}>
          <ModalHeader toggle={() => setShowErrors(false)}>
            Compute Errors
          </ModalHeader>
          <ModalBody style={{ maxHeight: "50vh", overflow: "auto" }}>
            <ol>
              {selected.errors.map((err, idx) => (
                <li key={idx}>{err}</li>
              ))}
            </ol>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setShowErrors(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
};

export default withAlertModal(ComputeHistoryPage);
