import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

export const ModernCrumbar: React.FC<{
  primary?: string;
  primaryPath?: string;
  secondary?: string;
  className?: string;
  children?: React.ReactNode;
}> = ({ primary, primaryPath, secondary, className, children }) => {
  return (
    <nav
      className={clsx(
        "navbar",
        "navbar-expand",
        "navbar-dark",
        "modern-crumbs",
        className,
      )}
    >
      {primary ? (
        <ol className="navbar-nav">
          {secondary && primaryPath ? (
            <li className="breadcrumb-item">
              <Link className="nav-link text-truncate" to={primaryPath}>
                {primary}
              </Link>
            </li>
          ) : null}
          <li className="breadcrumb-item active nav-link text-truncate">
            {secondary ?? primary}
          </li>
        </ol>
      ) : null}
      {children}
    </nav>
  );
};
