import { find, isEqual } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

import { ValidationError } from "../../../../admin/integrations/addEditIntegrationModal/common";
import JhSelect, { IDropListItem } from "../../../../common/JhSelect/JhSelect";
import { getReportData } from "../../../../craport/Craport";
import { columnToOption } from "../../utils";

const tableTypeOptons = [
  { label: "Standard Table", value: "normal" },
  { label: "Dynamic Table", value: "dynamic" },
];

const sortTypeOptions = [
  { label: "Descending", value: "desc" },
  { label: "Ascending", value: "asc" },
];

interface TableTypeFieldPropsType {
  tableType?: Record<string, any>;
  linkedReport?: string;
  validationError: ValidationError;
  onUnexpectedError: (e?: any) => void;
  updateTableType: (tableType: Record<string, any>) => void;
}

export const TableTypeField = (props: TableTypeFieldPropsType) => {
  const {
    tableType,
    linkedReport,
    validationError,
    updateTableType,
    onUnexpectedError,
  } = props;

  const [tableTypeOption, setTableTypeOption] = useState<IDropListItem>();
  const [sortColumnIdOptions, setSortColumnIdOptions] =
    useState<IDropListItem[]>();
  const [sortColumnIdOption, setSortColumnIdOption] = useState<IDropListItem>();
  const [sortTypeOption, setSortTypeOption] = useState<IDropListItem>();
  const [maxRowCount, setMaxRowCount] = useState<number>(1);

  const handleTypeChange = (value: IDropListItem) => {
    if (isEqual(value.value, tableTypeOptons[0].value))
      updateTableType({
        name: value.value,
        sortColumnId: undefined,
        sortType: undefined,
        rowCount: undefined,
      });
    else if (isEqual(value.value, tableTypeOptons[1].value))
      updateTableType({
        name: value.value,
        sortColumnId: 0,
        rowCount: 5,
        sortType: sortTypeOptions[0].value,
      });
  };

  const handleColumnChange = (value: IDropListItem) => {
    updateTableType({ sortColumnId: parseInt(value.value) });
  };

  const handleSortTypeChange = (value: IDropListItem) => {
    updateTableType({ sortType: value.value });
  };

  const handleRowCountChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.valueAsNumber;
    let rowCount;

    if (isNaN(inputValue)) rowCount = "";
    else if (inputValue > maxRowCount) rowCount = maxRowCount;
    else rowCount = e.target.valueAsNumber;

    updateTableType({ rowCount });
  };

  useEffect(() => {
    const selectedTableType = find(
      tableTypeOptons,
      (type) => type.value === (tableType?.name ?? ""),
    );
    const selectedColumnOption = find(
      sortColumnIdOptions,
      (option) => option.value === (tableType?.sortColumnId?.toString() ?? ""),
    );
    const selectedSortType = find(
      sortTypeOptions,
      (type) => type.value === (tableType?.sortType ?? ""),
    );

    setTableTypeOption(selectedTableType);
    setSortColumnIdOption(selectedColumnOption);
    setSortTypeOption(selectedSortType);
  }, [sortColumnIdOptions, tableType]);

  useEffect(() => {
    const fetchAndSetReportData = async () => {
      try {
        const reportData = linkedReport
          ? await getReportData(linkedReport, { properties: {}, sheet: 0 })
          : undefined;
        const columns = reportData?.sheets[0].columns.map(columnToOption);
        setSortColumnIdOptions(columns);
        setMaxRowCount(reportData?.sheets[0].rows.length ?? 1);
      } catch (error) {
        onUnexpectedError(error);
      }
    };

    fetchAndSetReportData();
  }, [linkedReport, onUnexpectedError]);

  const isDynamic = tableType?.name === tableTypeOptons[1].value;
  const isInvalidTableType = validationError.field === "tableType";
  const isInvalidSortColumnId = validationError.field === "sortColumnId";
  const isInvalidSortType = validationError.field === "sortType";
  const isInvalidRowCount = validationError.field === "rowCount";

  return (
    <>
      <FormGroup>
        <Label>Table type</Label>
        <JhSelect
          value={tableTypeOption}
          options={tableTypeOptons}
          onValueUpdate={handleTypeChange}
          isMulti={false}
          pointy
          invalid={isInvalidTableType}
        />
        {isInvalidTableType && validationError.message && (
          <FormFeedback className="jh-block">
            {validationError.message}
          </FormFeedback>
        )}
      </FormGroup>
      {isDynamic && (
        <>
          <FormGroup>
            <Label>Select a column for sort</Label>
            <JhSelect
              value={sortColumnIdOption}
              options={sortColumnIdOptions}
              onValueUpdate={handleColumnChange}
              isMulti={false}
              pointy
              invalid={isInvalidSortColumnId}
            />
            {isInvalidSortColumnId && validationError.message && (
              <FormFeedback className="jh-block">
                {validationError.message}
              </FormFeedback>
            )}
          </FormGroup>
          <div className="d-flex w-100 justify-content-between">
            <FormGroup style={{ width: "40%" }}>
              <Label>Sort type</Label>
              <JhSelect
                value={sortTypeOption}
                options={sortTypeOptions}
                onValueUpdate={handleSortTypeChange}
                isMulti={false}
                pointy
                invalid={isInvalidSortType}
              />
              {isInvalidSortType && validationError.message && (
                <FormFeedback className="jh-block">
                  {validationError.message}
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup style={{ width: "40%" }}>
              <Label>Row Count</Label>
              <Input
                type="number"
                value={tableType.rowCount}
                invalid={isInvalidRowCount}
                onChange={handleRowCountChange}
                min={1}
                max={maxRowCount}
              />
              {isInvalidRowCount && validationError.message && (
                <FormFeedback className="jh-block">
                  {validationError.message}
                </FormFeedback>
              )}
            </FormGroup>
          </div>
        </>
      )}
    </>
  );
};
